<template>
  <validation-observer ref="formRole">
    <form
      @submit.prevent="save"
    >
      <div class="animated fadeIn">
        <b-card class="px-md-2">
          <ResponseAlert ref="response" />
          <h3>{{ $route.meta.breadcrumb[1].text }}</h3>
          <b-row
            class="my-1 mt-3 px-md-4"
            align-v="center"
          >
            <b-col sm="2">
              <label
                class="h5"
                for="name"
              >Nama Role</label>
            </b-col>
            <b-col sm="9">
              <validation-provider
                #default="{ errors }"
                name="Nama"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="models.name"
                  type="text"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>

              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12">
              <div class="table-responsive role-management">
                <table class="table b-table">
                  <thead>
                    <tr>
                      <th width="50%">
                        Permission
                      </th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    <template
                      v-for="permission, idx in masters.permissions"
                    >
                      <tr :key="idx">
                        <td>
                          <b-form-checkbox
                            v-model="models.menuIds"
                            :value="permission.id"
                            name="role"
                            @change="toggleParent(permission)"
                          >
                            {{ permission.name }}
                          </b-form-checkbox>
                        </td>
                        <td>
                          <b-form-group v-slot="{ ariaDescribedby }">
                            <b-form-checkbox-group
                              v-model="models.permissionIds"
                              value-field="id"
                              class="text-capitalize"
                              :options="permission.permissions"
                              text-field="name"
                              :aria-describedby="ariaDescribedby"
                              name="child"
                              stacked
                              @change="togglePermission(permission)"
                            />
                          </b-form-group>
                        </td>
                      </tr>
                      <tr
                        v-for="childPermission, index in permission.children"
                        :key="`child-${idx}-${index}`"
                        class="child-permission"
                      >
                        <td>
                          <b-form-checkbox
                            v-model="models.menuIds"
                            name="role"
                            :value="childPermission.id"
                            @change="toggleChildren(permission, childPermission)"
                          >
                            {{ childPermission.name }}
                          </b-form-checkbox>
                        </td>
                        <td>
                          <b-form-group v-slot="{ ariaDescribedby }">
                            <b-form-checkbox-group
                              v-model="models.permissionIds"
                              value-field="id"
                              class="text-capitalize"
                              :options="childPermission.permissions"
                              text-field="name"
                              :aria-describedby="ariaDescribedby"
                              name="child"
                              stacked
                              @change="togglePermission(childPermission)"
                            />
                          </b-form-group>
                        </td>
                      </tr>

                    </template>
                  </tbody>
                </table>
              </div>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-center justify-content-md-end">
            <b-button
              type="button"
              variant="light"
              class="btn-min-width rounded"
              @click="$router.push({path: config.uri})"
            >
              Batal
            </b-button>
            <b-button
              type="submit"
              variant="primary"
              class="btn-min-width rounded ml-1"
            >
              Simpan
            </b-button>
          </div>
        </b-card>
      </div>
    </form>
  </validation-observer>

</template>

<script>
import api from '@/utils/api'
import {
  BCard, BRow, BCol, BButton, BFormInput, BFormCheckbox, BFormCheckboxGroup, BFormGroup,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line import/no-cycle
import { strSplit } from '@/utils/helpers'

export default {
  name: 'FormRole',
  metaInfo: {
    title: 'Form Role',
  },
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,

    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      config: {
        uri: `/${this.$route.meta.link}`,
      },
      models: {
        name: '',
        menuIds: [],
        permissionIds: [],
      },
      masters: {
        optionsCrud: ['Create', 'Edit', 'View', 'Delete'],
        permissions: [],
      },
      // Functions
      strSplit,
      required,
    }
  },
  created() {
    this.getById()
    this.getPermissions()
  },
  methods: {
    getById() {
      if (this.$route.params.id) {
        this.$axios.get(`${api.roles}/${this.$route.params.id}`)
          .then(res => {
            const { data } = res.data
            const menuIds = []
            const permissionIds = []

            data.menus.forEach(menu => {
              menuIds.push(menu.id)
              if (menu.children.length) {
                menu.children.forEach(child => {
                  menuIds.push(child.id)
                  child.permissions.forEach(permission => permissionIds.push(permission.id))
                })
              }
              if (menu.permissions.length) {
                menu.permissions.forEach(permission => permissionIds.push(permission.id))
              }
            })

            this.models.name = data.name
            this.models.menuIds = menuIds
            this.models.permissionIds = permissionIds
          }).catch(() => this.$router.push({
            name: 'page404',
          }))
      }
    },
    toggleParent(menu) {
      if (menu.children.length) {
        const childrenIds = []
        const permissionIds = []
        menu.children.forEach(c => {
          childrenIds.push(c.id)
          c.permissions.forEach(p => permissionIds.push(p.id))
        })

        if (this.models.menuIds.includes(menu.id)) {
          this.models.menuIds = [...this.models.menuIds, ...childrenIds]
          this.models.permissionIds = [...this.models.permissionIds, ...permissionIds]
        } else {
          this.models.menuIds = this.models.menuIds.filter(value => !childrenIds.includes(value))
          this.models.permissionIds = this.models.permissionIds.filter(value => !permissionIds.includes(value))
        }
      }
      if (!this.models.menuIds.includes(menu.id)) {
        // this.models.menuIds.push(menu.id)
        const permissionIds = menu.permissions.map(permission => permission.id)
        const permissionsToDeleteSet = new Set(permissionIds)
        this.models.permissionIds = this.models.permissionIds.filter(permission => !permissionsToDeleteSet.has(permission))
      } else {
        // Set default permission to check
        const permissionIds = menu.permissions.map(permission => permission.id)
        if (permissionIds.length) {
          this.models.permissionIds = [...this.models.permissionIds, ...permissionIds]
        }
      }
    },
    toggleChildren(menu, childrenMenu) {
      if (menu.children) {
        if (!this.models.menuIds.includes(menu.id) || this.models.menuIds.includes(childrenMenu.id)) {
          this.models.menuIds.push(menu.id)
          const permissionChildIds = childrenMenu.permissions.map(permission => permission.id)
          if (permissionChildIds.length) {
            this.models.permissionIds = [...this.models.permissionIds, ...permissionChildIds]
          }
        } else {
          const permissionIds = childrenMenu.permissions.map(permission => permission.id)
          const permissionsToDeleteSet = new Set(permissionIds)
          const childrenIds = menu.children.map(c => c.id)
          const child = this.models.menuIds.filter(value => childrenIds.includes(value))
          this.models.permissionIds = this.models.permissionIds.filter(permission => !permissionsToDeleteSet.has(permission))
          if (child.length === 0) {
            this.models.menuIds = this.models.menuIds.filter(m => m !== menu.id)
          }
        }
      }
    },
    togglePermission(menu) {
      if (!this.models.menuIds.includes(menu.id)) {
        this.models.menuIds.push(menu.id)

        // Check if parent menu doesn't exist
        if (menu.parentId && !this.models.menuIds.includes(menu.parentId)) {
          this.models.menuIds.push(menu.parentId)
        }
      } else {
        const permissionIds = menu.permissions.map(c => c.id)
        const permission = this.models.permissionIds.filter(value => permissionIds.includes(value))

        if (permission.length === 0) {
          this.models.menuIds = this.models.menuIds.filter(m => m !== menu.id)

          if (menu.parentId && this.models.menuIds.includes(menu.parentId)) {
            const permissionParent = this.masters.permissions.find(value => value.id === menu.parentId)
            if (permissionParent && permissionParent.children.length) {
              const childrenIds = permissionParent.children.map(child => child.id)
              const found = childrenIds.some(id => this.models.menuIds.includes(id))
              if (!found) {
                this.models.menuIds = this.models.menuIds.filter(m => m !== menu.parentId)
              }
            }
          }
        }
      }
    },
    getPermissions() {
      this.$axios.get(api.listPermissions, {
        params: {
          sortBy: 'id.DESC',
        },
      }).then(res => {
        // this.masters.permissions = [...res.data.data]
        this.masters.permissions = res.data.data.map(data => {
          data.permissions.forEach(permission => {
            // eslint-disable-next-line no-param-reassign
            permission.name = strSplit(permission.name, '/')
          })
          if (data.children.length) {
            data.children.forEach(child => {
              child.permissions.forEach(childPermission => {
                // eslint-disable-next-line no-param-reassign
                childPermission.name = strSplit(childPermission.name, '/')
              })
            })
          }
          return data
        })
      })
    },

    async setNewUserMenu() {
      try {
        const response = await Promise.all([
          this.$axios.get(api.user_menu),
        ])
        if (response[0].status === 200) {
          this.$store.dispatch('user/setMenus', response[0].data.data)
        }
        return true
      } catch (error) {
        console.log('error', error)
        return false
      }
    },

    save() {
      this.$refs.formRole.validate().then(async success => {
        if (success) {
          let request = ''
          let message = 'create'

          if (!this.$route.params.id) {
            request = this.$axios.post(api.roles, this.models)
          } else {
            message = 'update'
            request = this.$axios.put(`${api.roles}/${this.$route.params.id}`, this.models)
          }
          request
            .then(async () => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Success ${message} roles`,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })

              if (this.$store.state.user?.me.roleId == this.$route.params.id) {
                const response = await this.setNewUserMenu()
                if (response) {
                  this.$router.push(this.config.uri)
                }
              } else {
                this.$router.push(this.config.uri)
              }
            })
            .catch(error => this.$refs.response.setResponse(error.response.data, 'danger'))
        } else {
          window.scrollTo(0, 0)
        }
      })
    },
  },
}
</script>

<style>
table tr.child-permission td:nth-child(1) {
  padding-left: 4rem;
}
</style>
